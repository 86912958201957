<script>
import axios from "axios"
import store from '@/store/index'

export default {
  props: {
    // 是否需要更新表格中的数据
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 生活号列表
      lifeList: [],
    }
  },
  watch: {
    // 监听给组件绑定的是否需要更新表格中的数据
    value: {
      handler(newVal) {
        if (newVal) this.getLifeList()
      }
    }
  },
  methods: {
    // 获取生活号信息列表
    async getLifeList() {
      const res = await axios.get(store.state.global_base_url.main + 'life/list')
      this.lifeList = res.data.message
      // 通知父组件表格数据更新完成
      this.$emit('input', false)
    },
    // 删除生活号信息
    delLife(id) {
      this.$confirm('是否确认删除生活号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await axios.get(store.state.global_base_url.main + 'life/del?id=' + id)
        if (res.data.message) {
          await this.getLifeList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        } else {
          this.$message({
            type: 'error',
            message: '删除失败!'
          });
        }
      })
    },
    // 编辑生活号
    editLife(lifeInfo) {
      // 通知父组件需要编辑生活号信息，让其打开编辑对话框
      this.$emit('editLife', lifeInfo)
    }
  },
  created() {
    this.getLifeList()
  }
}
</script>

<template>
  <div class="life-table">
    <el-table :data="lifeList" style="width: 100%;">
      <el-table-column prop="lifeAppId" label="生活号 APP ID" width="180"></el-table-column>
      <el-table-column prop="lifeName" label="生活号名称" width="180"></el-table-column>
      <el-table-column prop="lifeAppPriKey" label="生活号应用私钥">
        <template slot-scope="scope">
          <div class="text-ellipsis">{{ scope.row.lifeAppPriKey }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="alipayPubKey" label="支付宝公钥">
        <template slot-scope="scope">
          <div class="text-ellipsis">{{ scope.row.alipayPubKey }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editLife(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="delLife(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped lang="less">
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>