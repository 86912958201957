<script>
import LifeEditorDialog from "@/components/life/components/LifeEditorDialog.vue";
import LifeTable from "@/components/life/components/LifeTable.vue";

export default {
  components: {LifeTable, LifeEditorDialog},
  data() {
    return {
      // 生活号编辑对话框是否显示
      lifeEditorDialogVisible: false,
      // 是否新增生活号
      isAddLife: false,
      // 是否更新生活号表格数据
      isUpdateLifeTable: false,
      // 要编辑的生活号信息
      editLifeInfo: {}
    }
  },
  methods: {
    // 打开生活号编辑对话框
    addLife() {
      // 新增的话，要编辑的生活号信息为空
      this.editLifeInfo = {
        id: '',
        lifeAppId: '',
        lifeAppPriKey: '',
        alipayPubKey: '',
        lifeName: '',
      }
      // 打开编辑对话框
      this.lifeEditorDialogVisible = true
      // 表明要新增生活号信息
      this.isAddLife = true
    },
    // 更新生活号表格数据
    updateLifeTable() {
      // 告诉表格子组件要更新数据
      this.isUpdateLifeTable = true
    },
    // 编辑生活号
    editLife(lifeInfo) {
      // 要编辑的生活号信息为空
      this.editLifeInfo = lifeInfo
      // 打开编辑对话框
      this.lifeEditorDialogVisible = true
      // 表明要编辑生活号信息
      this.isAddLife = false
    }
  }
}
</script>

<template>
  <div class="life-page-container">
    <div class="life-page">
      <div class="add-btn">
        <el-button type="primary" @click="addLife">新增生活号</el-button>
      </div>
      <div class="life-table">
        <LifeTable v-model="isUpdateLifeTable" @editLife="editLife"></LifeTable>
      </div>
    </div>
    <LifeEditorDialog
      v-model="lifeEditorDialogVisible"
      :is-add="isAddLife"
      :lifeInfo="editLifeInfo"
      @updateLifeTable="updateLifeTable"
    ></LifeEditorDialog>
  </div>
</template>

<style scoped lang="less">
.life-page-container {
  height: 100%;

  .life-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .add-btn {
      margin: 0 0 10px 0;
    }

    .life-table {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>