<script>
import axios from "axios"
import store from '@/store/index'

export default {
  props: {
    // 对话框是否显示
    value: {
      type: Boolean,
      default: false,
    },
    // 是否为添加生活号
    isAdd: {
      type: Boolean,
      default: false,
    },
    // 生活号信息
    lifeInfo: {
      default: () => ({
        id: '',
        lifeAppId: '',
        lifeAppPriKey: '',
        alipayPubKey: '',
        lifeName: '',
      })
    }
  },
  data() {
    return {
      // 对话框是否显示
      visible: false,
      // 生活号表单
      lifeForm: {
        id: '',
        lifeAppId: '',
        lifeAppPriKey: '',
        alipayPubKey: '',
        lifeName: '',
      }
    }
  },
  watch: {
    // 监听 v-model 绑定的值（对话框是否显示）的变化
    value: {
      handler(newVal) {
        this.visible = newVal
      },
      immediate: true,
    },
    // 监听通过组件参数传递进来的生活号信息
    lifeInfo: {
      handler(newVal) {
        this.lifeForm = newVal
      },
      deep: true
    }
  },
  methods: {
    // 处理对话框关闭
    handleClose() {
      this.$emit('input', false)
    },
    // 关闭对话框
    close() {
      this.visible = false
      this.handleClose()
    },
    // 校验表单填写
    verify() {
      if (this.lifeForm.lifeAppId.length <= 0) {
        this.$message({
          message: '请填写生活号 APP ID',
          type: 'warning'
        });
        return false
      }
      if (this.lifeForm.lifeName.length <= 0) {
        this.$message({
          message: '请填写生活号名称',
          type: 'warning'
        });
        return false
      }
      if (this.lifeForm.lifeAppPriKey.length <= 0) {
        this.$message({
          message: '请填写生活号应用密钥',
          type: 'warning'
        });
        return false
      }
      if (this.lifeForm.alipayPubKey.length <= 0) {
        this.$message({
          message: '请填写支付宝公钥',
          type: 'warning'
        });
        return false
      }
      return true
    },
    // 保存生活号信息
    async save() {
      // 校验表单信息
      if (!this.verify()) return
      // 发送请求保存生活号信息
      const res = await axios.post(
        store.state.global_base_url.main + 'life/save',
        this.lifeForm
      )
      if (res.data.message) {
        this.$message({
          message: '生活号保存成功',
          type: 'success'
        });
        // 告诉父组件需要更新表格数据
        this.$emit('updateLifeTable')
        // 恢复当前组件中的数据
        this.lifeForm = {
          id: '',
          lifeAppId: '',
          lifeAppPriKey: '',
          alipayPubKey: '',
          lifeName: '',
        }
        this.visible = false
        this.handleClose()
        return
      }
      this.$message({
        message: '生活号保存失败',
        type: 'error'
      });
    }
  },
}
</script>

<template>
  <div class="life-editor-dialog">
    <el-dialog
      :title="isAdd ? '新增生活号' : '编辑生活号'"
      :visible.sync="visible"
      width="50%"
      @closed="handleClose"
    >
      <el-form label-position="left" label-width="auto">
        <el-form-item label="生活号 APP ID" required>
          <el-input v-model="lifeForm.lifeAppId" clearable/>
        </el-form-item>
        <el-form-item label="生活号名称" required>
          <el-input v-model="lifeForm.lifeName" />
        </el-form-item>
        <el-form-item label="生活号应用私钥" required>
          <el-input v-model="lifeForm.lifeAppPriKey" clearable/>
        </el-form-item>
        <el-form-item label="支付宝公钥" required>
          <el-input v-model="lifeForm.alipayPubKey" clearable/>
        </el-form-item>
      </el-form>
      <div class="life-editor-dialog__btn">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.life-editor-dialog {

  .life-editor-dialog__btn {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>